// Terms & Conditions
const TermsAndConditions = [
  {
    content: `
      <h2>General Terms</h2>
      <p>
        These following terms describe the Website Terms of Use upon which you
        may make use of clearfactor.io (and all of its sub-domains, together
        the “Website”), whether as a guest or a registered user. Use of our
        Website includes accessing, browsing and registering to use the
        Website.
      </p>
      <p>
        Our Website is operated by Clear Factor Limited (Clear Factor). Clear
        Factor is registered in England and Wales under company number
        11269195 with its registered office at Third Floor, 126-134 Baker Street, London W1U 6UE, United Kingdom.
      </p>
      <p>
        If you continue to browse and use this Website you are agreeing to
        comply with and be bound by the following terms and conditions of use,
        which together with our privacy policy govern Clear Factor’s
        relationship with you in relation to this Website.
      </p>
      <p>
        By using our Website, you also consent to our
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.satago.com/legal/privacy"
        >
          Privacy &amp; Data Protection Policy
        </a>
        and you warrant that all data provided by you is accurate.
      </p>
      <p>
        Clear Factor may revise these Terms of Use from time to time by
        amending this page and it is your responsibility to review our
        policies as they are binding on you.
      </p>
      <p>
        Neither we nor any third parties provide any warranty or guarantee as
        to the accuracy, timeliness, performance, completeness or suitability
        of the information and materials found or offered on this Website for
        any particular purpose. You acknowledge that such information and
        materials may contain inaccuracies or errors and we expressly exclude
        liability for any such inaccuracies or errors to the fullest extent
        permitted by law.
      </p>
      <p>
        Clear Factor shall use reasonable endeavours but do not guarantee that
        our Website, or any content on it (including the invoice submission
        and auction functions) or received from it, will always be available
        or be uninterrupted. Access to our Website is permitted on a temporary
        basis. Clear Factor may suspend, withdraw, discontinue or change all
        or any part of our Website without notice. Clear Factor will not be
        liable to you if for any reason our Website is unavailable at any time
        or for any period.
      </p>
      <p>
        You are responsible for making all arrangements necessary to have
        access to our Website. You are also responsible for ensuring that all
        persons who access our Website through your internet connection are
        aware of these Terms of Use and other applicable terms and conditions,
        and that they comply with them.
      </p>
      <p>
        You are solely responsible for the accuracy and reliability of any
        information submitted to or through the Website by you, and you
        warrant that any information you submit to or through the Website is
        clear and accurate in all material respects and is not misleading.
      </p>
      <p>
        Our Website uses cookies to distinguish you from other users. This
        helps us provide you with a better browsing experience. By registering
        as a user of the Website and continuing to browse the Website, you are
        agreeing to our use of cookies.
      </p>
      <h2>Your Account and Password</h2>
      <p>
        If you choose, or are provided with a user identification code,
        password or any other piece of information as part of our security
        procedures, you must treat such information as confidential and not
        disclose it to any third party. &nbsp;If you disclose or allow to be
        disclosed any information to any person or third party which allows
        them&nbsp;access to your account, then Clear Factor will not not
        liable for any losses incurred as a result.
      </p>
      <p>
        Clear Factor have the right to disable any user identification code or
        password, whether chosen by you or allocated by us, at any time, if in
        our reasonable opinion you have failed to comply with any of the
        provisions of these Terms of Use.
      </p>
      <p>
        If you know or suspect that anyone other than you has obtained access
        to your user identification code or password, you must promptly notify
        us at
        <a href="mailto:support@clearfactor.io">support@clearfactor.io</a>.
      </p>
      <p>
        <h2>Data Security</h2>
      </p>
      <p>
        We will use technical and organisational measures in accordance with
        good industry practice to safeguard your information and Personal
        Data, including data encryption and secure data storage.
      </p>
      <p>
        While we will use all reasonable efforts to safeguard your information
        and Personal Data, you acknowledge that the use of the internet is not
        entirely secure and for this reason we cannot guarantee the security
        or integrity of any information that is transferred from you or to you
        via the internet.
      </p>
      <p>
        <h2>Intellectual Property Rights</h2>
      </p>
      <p>
        Clear Factor is the owner or licensee for all intellectual property
        rights on our Website and for any material published on it. The
        content is protected by worldwide copyright laws and treaties. All
        such rights are reserved.
      </p>
      <p>
        References in these terms of use to&nbsp;Intellectual Property
        Rights&nbsp;means copyright, patents, rights in inventions, rights in
        confidential information, know-how, trade secrets, trademarks, service
        marks, trade names, design rights, rights in get-up, database rights,
        rights in data, domain names, rights in computer software and all
        similar rights of whatever nature and, in each case: (i) whether
        registered or not, (ii) including any applications to protect or
        register such rights, (iii) including all renewals and extensions of
        such rights or applications, (iv) whether vested, contingent or future
        and (v) wherever in the world they exist
      </p>
      <p>
        You may print copies or download extracts of any page from our Website
        for your personal use and you may draw the attention of others within
        your organisation to content posted on our Website. However, you must
        not modify the document or digital copies of any material you have
        printed or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text. Our status (and that of any
        identified contributors) as the authors of content on our Website must
        always be acknowledged.
      </p>
      <p>
        You must not use any part of the content on our Website for commercial
        purposes without obtaining a license to do so from us. If you print,
        copy or download any part of our Website in breach of these Terms of
        Use, your right to use our Website will cease immediately and you will
        need to, at our option, return or destroy any copies of the materials
        you have made.
      </p>
      <p>
        <h2>Limitation of Liability</h2>
      </p>
      <p>
        Nothing in these Terms of Use excludes or limits our liability for
        death or personal injury arising from our negligence, or our fraud or
        fraudulent misrepresentation, or any other liability that cannot be
        excluded or limited by English law.
      </p>
      <p>
        To the extent permitted by law, Clear Factor exclude all conditions,
        warranties, representations or other terms which may apply to our
        Website or any content on it, whether express or implied.
      </p>
      <p>
        Clear Factor will not be liable to any user for any loss or damage,
        whether in contract, tort (including negligence), breach of statutory
        duty, or otherwise, even if foreseeable, arising under or in
        connection with:
      </p>
      <ul>
        <li>use of, or inability to use, our Website; or</li>
        <li>
          use of or reliance on any content displayed on or made available
          from our Website.
        </li>
      </ul>
      <p>
        Please note that in particular, Clear Factor will not be liable for:
      </p>
      <ul>
        <li>loss of profits, sales, business, or revenue;</li>
        <li>business interruption;</li>
        <li>loss of anticipated savings;</li>
        <li>loss of business opportunity, goodwill or reputation; or</li>
        <li>any indirect or consequential loss or damage.</li>
      </ul>
      <p>
        Clear Factor will not be liable for any loss or damage caused by a
        virus, distributed denial-of-service attack, or other technologically
        harmful material that may infect your computer equipment, computer
        programmes, data or other proprietary material due to your use of our
        Website or to your downloading of any content from it, or on any Clear
        Factor Website linked to it.
      </p>
      <p>
        Clear Factor assumes no responsibility for the content of third party
        Websites linked from our Website. Such links should not be interpreted
        as endorsement by us of those linked Websites. Clear Factor will not
        be liable for any loss or damage that may arise from your use of them.
      </p>
      <p>
        <h2>Viruses</h2>
      </p>
      <p>
        Clear Factor does not guarantee that our Website will be secure or
        free from bugs or viruses. You are responsible for configuring your
        information technology, computer programmes and platform in order to
        access our Website and use your own virus protection software.
      </p>
      <p>
        You must not misuse our Website by knowingly introducing viruses,
        trojans, worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to our Website, the server on which our Website is stored or
        any server, computer or database connected to our Website. You must
        not attack our Website via a denial-of-service attack or a distributed
        denial-of service attack. By breaching this provision, you would
        commit a criminal offence under the Computer Misuse Act 1990. Clear
        Factor will report any such breach to the relevant law enforcement
        authorities and Clear Factor will co-operate with those authorities by
        disclosing your identity to them. In the event of such a breach, your
        right to use our Website will cease immediately.
      </p>
      <p>
        <h2>Links to our Website</h2>
      </p>
      <p>
        You may link to our home page, provided you do so in a way that is
        fair and legal and does not damage our reputation or take advantage of
        it. You must not establish a link in such a way as to suggest any form
        of association, approval or endorsement on our part where none exists.
        You must not establish a link to our Website in any Website that is
        not owned by you nor frame our Website on any other Website.
      </p>
      <p>
        Clear Factor reserves the right to withdraw linking permission without
        notice.
      </p>
      <p>
        <h2>Applicable Law</h2>
      </p>
      <p>
        These Terms of Use, its subject matter and its formation (and any
        non-contractual disputes or claims) are governed by English law. By
        using the Website you agree to the exclusive jurisdiction of the
        courts of England and Wales.
      </p>
      `,
  },
]

export default TermsAndConditions
