import React from "react"

// store
import TermsAndConditionsContent from "../store/terms-and-conditions"

// components
import Layout from "../components/layout"

const TermsAndConditions = () => (
  <Layout
    title="Terms &amp; Conditions"
    description="These following terms describe the Website Terms of Use upon which you may make use of clearfactor.io"
  >
    <section className="site-container py-32">
      <div className="terms-and-policy" dangerouslySetInnerHTML={{ __html: TermsAndConditionsContent.map(i => i.content) }} />
    </section>
  </Layout>
)

export default TermsAndConditions
